import { useEffect, useRef } from 'react';
import * as shopSelectors from '../selectors';
import * as shopActions from '../actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Button } from '../../../stories/Button';

function unpackB64(b64Data: string): Uint8Array {
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  return new Uint8Array(byteNumbers);
}

const mapStateToProps = (state: RootState) => ({
  contractPreview: shopSelectors.contractPreview(state.shop),
  cart: shopSelectors.currentCart(state.shop),
});

const dispatchProps = {
  renderContract: shopActions.renderContractAsync.request,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopDownloadContractButton: React.FC<Props> = ({
  contractPreview,
  renderContract,
  cart,
}) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);

  const currentB64Content = contractPreview?.data?.base64Content ?? '';

  useEffect(() => {
    if (currentB64Content === '') {
      return;
    }
    const blob = new Blob([unpackB64(currentB64Content)], {
      type: 'application/pdf',
    });
    const blobUrl = window.URL.createObjectURL(blob);
    buttonRef.current?.setAttribute('href', blobUrl);
    buttonRef.current?.click();
    window.URL.revokeObjectURL(blobUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentB64Content]);

  return (
    <>
      <Button
        onClick={() => {
          renderContract(cart);
        }}
        label={'Vertrag'}
        loading={contractPreview?.loading ?? false}
      />
      {
        // eslint-disable-next-line
        <a
          ref={buttonRef}
          style={{ display: 'hidden' }}
          download={`contract.pdf`}
          target="_blank"
          rel="noreferer noreferrer"
        />
      }
    </>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ShopDownloadContractButton);
