import { connect } from 'react-redux';
import * as shopSelectors from '../selectors';
import * as shopActions from '../actions';
import { RootState } from 'typesafe-actions';
import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import ShopWizard from '../components/ShopWizard';
import ShopCartProvider from '../components/ShopCartProvider';

const mapStateToProps = (state: RootState) => ({
  accessibleCustomers: shopSelectors.accessibleCustomers(state.shop),
});

const dispatchProps = {
  getAccessibleCustomers: shopActions.getShopAccessibleCustomersAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const ShopPage: React.FC<Props> = ({
  getAccessibleCustomers,
  accessibleCustomers,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState<string>('none');

  // const { plan } = useParams<{
  //   plan?: string;
  // }>();

  useEffect(() => {
    if (
      !accessibleCustomers.loading &&
      !accessibleCustomers.data &&
      !accessibleCustomers.error
    ) {
      getAccessibleCustomers();
    }
  }, [accessibleCustomers, getAccessibleCustomers]);

  if (accessibleCustomers.loading) {
    return <div>Loading...</div>;
  }

  if (selectedCustomer === 'none') {
    return (
      <Select
        defaultValue={'none'}
        onChange={event => setSelectedCustomer(event.target.value)}
      >
        <MenuItem
          value={'none'}
          key={'none'}
          sx={{ fontFamily: 'WestfalenNewsSans' }}
        >
          None
        </MenuItem>
        {accessibleCustomers.data?.map(customer => (
          <MenuItem
            value={customer}
            key={customer}
            sx={{ fontFamily: 'WestfalenNewsSans' }}
          >
            {customer}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <ShopCartProvider sapCustomerNo={selectedCustomer}>
      <ShopWizard sapCustomerNo={selectedCustomer}></ShopWizard>
    </ShopCartProvider>
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopPage);
