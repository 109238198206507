import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Button } from '../../../stories/Button';
import * as commonSelectors from '../../common/selectors';
import * as shopActions from '../actions';
import * as shopSelectors from '../selectors';
import ShopCheckout from './ShopCheckout';
import styles from './ShopWizard.module.scss';
import { Progress } from '../../../stories/Progress';
import { ShopItem } from '../../../stories/ShopItem';
import { Devider } from '../../../stories/Devider';
import useWindowSize from '../../../hooks/useWindowSize';
import classNames from 'classnames';
import ShopConfiguratorStepUser from './ShopConfiguratorStepUser';
import ShopConfiguratorStepMeter from './ShopConfiguratorStepMeter';
import ShopConfiguratorStepGateways from './ShopConfiguratorStepGateways';
import ShopSummary from './ShopSummary';
import ShopFullscreenLoading from './ShopFullscreenLoading';

const mapStateToProps = (state: RootState) => ({
  user: commonSelectors.user(state.common),
  shopItems: shopSelectors.shopItems(state.shop),
  addresses: (sapCustomerNo: string) =>
    shopSelectors.addresses(sapCustomerNo, state.shop),
});

const dispatchProps = {
  calculateCart: shopActions.calculateCartAsync.request,
  requestShopItems: shopActions.getShopItemsAsync.request,
  getAddresses: shopActions.getShopAddressesAsync.request,
  changeItemQuantity: shopActions.currentCartChangeItemQuantity,
};

interface ComponentProps {
  sapCustomerNo: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopWizard: React.FC<Props> = ({
  calculateCart,
  user,
  shopItems,
  requestShopItems,
  getAddresses,
  addresses,
  sapCustomerNo,
  changeItemQuantity,
}) => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!shopItems.loading && !shopItems.data && !shopItems.error) {
      requestShopItems();
    }
  }, [shopItems.data, shopItems.loading, shopItems.error, requestShopItems]);

  const addressesForCustomer = addresses(sapCustomerNo);
  useEffect(() => {
    if (
      !addressesForCustomer.loading &&
      !addressesForCustomer.data &&
      !addressesForCustomer.error
    ) {
      getAddresses({ sapCustomerNo });
    }
  }, [
    addressesForCustomer.data,
    addressesForCustomer.loading,
    addressesForCustomer.error,
    getAddresses,
    sapCustomerNo,
  ]);

  const { data: shopItemEntries, loading: shopItemEntriesLoading } = shopItems;
  const { data: addressData, loading: addressDataLoading } =
    addressesForCustomer;

  const onItemQuantityChanged = useCallback(
    (itemId: string, quantity: number) => {
      const shippingAddressId = addressData!.billingAddress!.addressId;
      changeItemQuantity({ itemId, quantity, shippingAddressId });
    },
    [changeItemQuantity, addressData]
  );

  const onItemQuantityAndAddressChanged = useCallback(
    (itemId: string, quantity: number, shippingAddressId: string) => {
      changeItemQuantity({ itemId, quantity, shippingAddressId });
    },
    [changeItemQuantity]
  );

  let { width } = useWindowSize();

  if (
    shopItemEntriesLoading ||
    !shopItemEntries ||
    addressDataLoading ||
    !addressData
  ) {
    return <ShopFullscreenLoading />;
  }

  const ConfiguratorStep = () => {
    if (currentPageIndex === 0) {
      return (
        <ShopConfiguratorStepUser
          shopItemEntries={shopItemEntries}
          onUserQuantityChanged={onItemQuantityChanged}
        />
      );
    } else if (currentPageIndex === 1) {
      return (
        <ShopConfiguratorStepMeter
          shopItemEntries={shopItemEntries}
          onItemQuantityAndAddressChanged={onItemQuantityAndAddressChanged}
          addressData={addressData}
        />
      );
    } else if (currentPageIndex === 2) {
      return (
        <ShopConfiguratorStepGateways
          shopItemEntries={shopItemEntries}
          addressData={addressData}
          onItemQuantityAndAddressChanged={onItemQuantityAndAddressChanged}
        />
      );
    } else if (currentPageIndex === 3 && user) {
      return (
        <ShopCheckout
          selectedShippingAddress={selectedShippingAddress}
          setSelectedShippingAddress={setSelectedShippingAddress}
          addresses={addressData}
        />
      );
    }
  };

  return (
    <div className={styles['gaas-shopPage--background']}>
      <div className={styles['gaas-shopPage']}>
        <h1>Gas as a Service®</h1>
        <Progress
          labels={['Nutzeranzahl', 'GaaS Meter', 'GaaS Gate', 'Bestätigen']}
          currentIndex={currentPageIndex}
        />

        <div
          className={classNames(styles['gaas-shopPage--content'], {
            [styles['gaas-shopPage--content--checkout']]:
              currentPageIndex === 3,
          })}
        >
          <div className={styles['gaas-shopPage--content--selection']}>
            <ShopItem currentIndex={currentPageIndex} />
            <Devider noMargin />
            {ConfiguratorStep()}
            <Devider noMargin />
          </div>

          <div className={styles['gaas-shopPage--content--summary']}>
            <ShopSummary
              selectedShippingAddress={selectedShippingAddress}
              isAtCheckout={currentPageIndex === 3}
              shopItemEntries={shopItemEntries}
            />
            <Button
              variant="outlined"
              onClick={calculateCart}
              label="RecalcCart"
            />
          </div>

          <div className={styles['gaas-shopPage--content--buttonRow']}>
            {currentPageIndex > 0 && currentPageIndex < 4 && (
              <Button
                label="Zurück"
                variant="outlined"
                fullWidth={width < 1024}
                onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
              />
            )}

            {currentPageIndex < 3 && (
              <Button
                label={`Weiter zu Schritt ${currentPageIndex + 2}`}
                fullWidth={width < 1024}
                onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopWizard);
