import React, { ChangeEvent, useState } from 'react';
import styles from './Select.module.scss';
import classNames from 'classnames';
import { LabelAndText } from './LabelAndText';
import { ChevronDownSmall, Info } from './icons';
import { TextButton } from './TextButton';
import { InfoBox } from './InfoBox';

interface Props {
  label: string;
  name: string;
  id?: string;
  options: { label: string; value: any }[];
  value?: any;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  error?: boolean;
  errorMessage?: string;
  size?: 's' | 'm' | 'l';
  unit?: string;
  info?: string;
}

export const Select: React.FC<Props> = ({
  label,
  name,
  id = name,
  options,
  value,
  onChange,
  disabled,
  required,
  multiple,
  error,
  errorMessage,
  size,
  unit,
  info,
}) => {
  const [infoVisible, setInfoVisible] = useState<boolean>(false);

  return (
    <div
      className={classNames(
        styles['gaas-select'],
        styles[`gaas-select--${size}`],
        {
          [styles['gaas-select--disabled']]: disabled,
          [styles['gaas-select--error']]: error,
        }
      )}
    >
      <div className={styles['gaas-select--label']}>
        <LabelAndText label={label} labelFor={id} />
        {info && (
          <TextButton
            LeadingIcon={Info}
            onClick={() => setInfoVisible(!infoVisible)}
            inline
          />
        )}
      </div>
      <select
        name={name}
        id={id}
        onChange={onChange}
        disabled={disabled}
        required={required}
        multiple={multiple}
        value={value}
      >
        <option value="">Auswählen</option>
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {infoVisible && (
        <InfoBox>
          <span>{info}</span>
        </InfoBox>
      )}
      <ChevronDownSmall className={styles['gaas-select--icon']} />
      {/* <ChevronUpSmall className={styles['gaas-select--icon']} /> */}
      {unit && <span className={styles['gaas-select--unit']}>{unit}</span>}
      {error && <p className={styles['gaas-select--hint']}>{errorMessage}</p>}
    </div>
  );
};
