import { makeAsyncAction } from '../../util/makeAsyncAction';
import {
  ApiCalculateCartRequest,
  ApiCalculateCartResponse,
  ApiCartItem,
  ApiGetShopAccessibleCustomers,
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
  ApiRenderContractResponse,
  ApiSubmitCartRequest,
} from '../../models/openapi/openapiTypes';
import { createAction } from 'typesafe-actions';

export const calculateCartAsync = makeAsyncAction('CALCULATE_CART')<
  void,
  ApiCalculateCartResponse,
  { cartId: string; error: Error }
>();

export const renderContractAsync = makeAsyncAction('RENDER_CONTRACT')<
  ApiCalculateCartRequest,
  { cartId: string; contract: ApiRenderContractResponse },
  { cartId: string; error: Error }
>();

export const getShopAddressesAsync = makeAsyncAction('GET_SHOP_ADDRESSES')<
  { sapCustomerNo: string },
  { sapCustomerNo: string; addresses: ApiGetShopAddressesResponse },
  { sapCustomerNo: string; error: Error }
>();

export const getShopAccessibleCustomersAsync = makeAsyncAction(
  'GET_SHOP_ACCESSIBLE_CUSTOMERS'
)<void, ApiGetShopAccessibleCustomers, Error>();

export const submitCartAsync = makeAsyncAction('SUBMIT_CART')<
  ApiSubmitCartRequest,
  { cartId: string },
  { cartId: string; error: Error }
>();

export const getShopItemsAsync = makeAsyncAction('GET_SHOP_ITEMS')<
  void,
  ApiGetShopItemsResponse,
  Error
>();

export const loadOrCreateCartForCustomer = createAction('LOAD_OR_CREATE_CART')<{
  sapCustomerNo: string;
  defaultItems: ApiCartItem[];
}>();

export const currentCartChangeBillingInterval = createAction(
  'CHANGE_BILLING_INTERVAL'
)<'monthly' | 'yearly'>();
export const currentCartChangeItemQuantity = createAction(
  'CHANGE_ITEM_QUANTITY'
)<ApiCartItem>();

// export const currentCartChangeItemQuantity
// export const currentCartChangeBillingInterval
// export const currentCartRecalculate
// export const currentCartSubmit

export const storeCartLocal =
  createAction('SAVE_CART')<ApiCalculateCartRequest>();
