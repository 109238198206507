import { Epic } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf, RootAction, RootState, Services } from 'typesafe-actions';
import { of, from } from 'rxjs';
import {
  calculateCartAsync,
  currentCartChangeBillingInterval,
  currentCartChangeItemQuantity,
  getShopAccessibleCustomersAsync,
  getShopAddressesAsync,
  getShopItemsAsync,
  loadOrCreateCartForCustomer,
  renderContractAsync,
  storeCartLocal,
  submitCartAsync,
} from './actions';
import { SavedCart } from './models/SavedCart';
import { v4 as uuidv4 } from 'uuid';
import { ApiCalculateCartRequest } from '../../models/openapi/openapiTypes';

export const calculateCart: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { shop }) =>
  action$.pipe(
    filter(isActionOf(calculateCartAsync.request)),
    withLatestFrom(state),
    switchMap(([_, state]) =>
      shop.calculateCart(state.shop.currentCart).pipe(
        map(cart => calculateCartAsync.success(cart)),
        catchError(error =>
          of(
            calculateCartAsync.failure({
              cartId: state.shop.currentCart.cartId,
              error,
            })
          )
        )
      )
    )
  );

export const submitCart: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { shop }
) =>
  action$.pipe(
    filter(isActionOf(submitCartAsync.request)),
    switchMap(({ payload }) =>
      shop.submitCart(payload).pipe(
        map(() => submitCartAsync.success({ cartId: payload.cart.cartId })),
        catchError(error =>
          of(
            submitCartAsync.failure({
              cartId: payload.cart.cartId,
              error,
            })
          )
        )
      )
    )
  );

export const renderContract: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(renderContractAsync.request)),
    switchMap(({ payload }) =>
      shop.renderContract(payload).pipe(
        map(doc =>
          renderContractAsync.success({ cartId: payload.cartId, contract: doc })
        ),
        catchError(error =>
          of(renderContractAsync.failure({ cartId: payload.cartId, error }))
        )
      )
    )
  );

export const getShopAddresses: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(getShopAddressesAsync.request)),
    switchMap(({ payload: { sapCustomerNo } }) =>
      shop.getAddresses(sapCustomerNo).pipe(
        map(addresses =>
          getShopAddressesAsync.success({ sapCustomerNo, addresses })
        ),
        catchError(error =>
          of(getShopAddressesAsync.failure({ sapCustomerNo, error }))
        )
      )
    )
  );

export const getShopItems: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { shop }
) =>
  action$.pipe(
    filter(isActionOf(getShopItemsAsync.request)),
    switchMap(() =>
      shop.getShopItems().pipe(
        map(items => getShopItemsAsync.success(items)),
        catchError(error => of(getShopItemsAsync.failure(error)))
      )
    )
  );

export const getShopAccessibleCustomers: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(getShopAccessibleCustomersAsync.request)),
    switchMap(() =>
      shop.getAccessibleCustomers().pipe(
        map(items => getShopAccessibleCustomersAsync.success(items)),
        catchError(error => of(getShopAccessibleCustomersAsync.failure(error)))
      )
    )
  );

export const onLoadOrCreateCartForCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { shop }) =>
  action$.pipe(
    filter(isActionOf(loadOrCreateCartForCustomer)),
    map(
      ({
        payload: { sapCustomerNo, defaultItems },
      }): ApiCalculateCartRequest => {
        const cartJson = localStorage.getItem(`GAAS-CART-${sapCustomerNo}`);
        if (cartJson) {
          const parsedCartCandidate = JSON.parse(cartJson) as SavedCart;
          if (parsedCartCandidate.version === 'v1') {
            return parsedCartCandidate.cart;
          }
        }

        return {
          billingInterval: 'monthly',
          cartId: uuidv4(),
          items: defaultItems,
          sapCustomerNo,
        };
      }
    ),
    switchMap(cart =>
      from([storeCartLocal(cart), calculateCartAsync.request()])
    )
  );

export const onChangeBillingInterval: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { shop }) =>
  action$.pipe(
    filter(isActionOf(currentCartChangeBillingInterval)),
    withLatestFrom(state),
    switchMap(([{ payload: billingInterval }, state]) =>
      of({
        ...state.shop.currentCart,
        billingInterval,
      })
    ),
    switchMap(cart =>
      from([storeCartLocal(cart), calculateCartAsync.request()])
    )
  );

export const onChangeItemQuantity: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state, { shop }) =>
  action$.pipe(
    filter(isActionOf(currentCartChangeItemQuantity)),
    withLatestFrom(state),
    switchMap(([{ payload: cartItem }, state]) => {
      const currentCart = state.shop.currentCart;
      let currentCartItems = currentCart.items;
      // const idx = currentCartItems.findIndex((item) => item.itemId === cartItem.itemId && item.shippingAddressId === cartItem.shippingAddressId)
      // if(idx !== -1) {
      //   currentCartItems = [...currentCartItems].splice(idx,1)
      // }

      currentCartItems = currentCartItems.filter(
        item =>
          item.itemId !== cartItem.itemId ||
          item.shippingAddressId !== cartItem.shippingAddressId
      );

      if (cartItem.quantity > 0) {
        currentCartItems.push(cartItem);
      }

      return of({
        ...currentCart,
        items: currentCartItems,
      });
    }),
    switchMap(cart =>
      from([storeCartLocal(cart), calculateCartAsync.request()])
    )
  );
