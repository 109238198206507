import React, { useState } from 'react';
import styles from './ShopWizard.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { Info } from '../../../stories/icons';
import { InfoBox } from '../../../stories/InfoBox';
import ShopItemInput from './ShopItemInput';
import { ApiGetShopItemsResponse } from '../../../models/openapi/openapiTypes';

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  onUserQuantityChanged: (itemId: string, quantity: number) => void;
}

const ShopConfiguratorStepUser: React.FC<ComponentProps> = ({
  shopItemEntries,
  onUserQuantityChanged,
}) => {
  const [infoVisible, setInfoVisible] = useState<boolean>(false);

  const users = shopItemEntries.filter(it => it.category === 'user');

  return (
    <>
      <div className={styles['gaas-shopPage--content--selection--info']}>
        <span>Wählen Sie die Anzahl Nutzer aus:</span>
        <TextButton
          LeadingIcon={Info}
          onClick={() => setInfoVisible(!infoVisible)}
          inline
        />
      </div>
      {infoVisible && (
        <InfoBox>
          <span>
            Sie können Ihre BASIC- und PREMIUM-User-Lizenzen nach Bedarf erhöhen
            oder reduzieren. Nach Abschluss des Vertrages wird die Anzahl
            Lizenzen umgehend geändert, die angepasste Abrechnung beginnt im
            darauf folgenden Monat.
          </span>
        </InfoBox>
      )}
      <div className={styles['gaas-shopPage--content--selection--user']}>
        {users.map(userItem => (
          <div
            className={styles['gaas-shopPage--content--selection--input']}
            key={userItem.itemId}
          >
            <ShopItemInput
              key={userItem.itemId}
              itemId={userItem.itemId}
              itemName={userItem.name}
              onItemQuantityChanged={onUserQuantityChanged}
            />
            <span
              className={
                styles['gaas-shopPage--content--selection--input--label']
              }
            >
              Pro Nutzer {userItem.price}.00 EUR / Mon.
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default ShopConfiguratorStepUser;
