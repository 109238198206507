import React, { useEffect, useState } from 'react';
import { Modal } from '../../../stories/Modal';
import { Select } from '../../../stories/Select';
import styles from './ChangeAddressModal.module.scss';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { LabelAndText } from '../../../stories/LabelAndText';
import { Skeleton } from '../../../stories/Skeleton';
import { ApiGetShopAddressesResponse } from '../../../models/openapi/openapiTypes';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  shippingAddressId?: string;
  setShippingAddressId: (addressId?: string) => void;
  addresses: ApiGetShopAddressesResponse;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ChangeAddressModal: React.FC<Props> = ({
  open,
  onClose,
  addresses,
  shippingAddressId,
  setShippingAddressId,
}) => {
  const [selectedShippingAddressId, setSelectedShippingAddressId] = useState<
    string | undefined
  >(shippingAddressId);

  useEffect(() => {
    setShippingAddressId(addresses.shippingAddresses[0].addressId);
  }, [addresses, setShippingAddressId]);

  let address = (
    <div>
      <LabelAndText label="Lieferadresse" />
      <Skeleton height={40} mt={4} mb={24} />
      <Skeleton height={20} width={150} mb={4} />
      <Skeleton height={20} width={150} mb={4} />
      <Skeleton height={20} width={150} mb={4} />
      <Skeleton height={20} width={120} />
    </div>
  );

  if (shippingAddressId !== undefined) {
    const addressIdContent = addresses.shippingAddresses.find(
      address => address.addressId === selectedShippingAddressId
    );
    address = (
      <>
        <Select
          label="Lieferadresse"
          name="address"
          options={addresses.shippingAddresses.map(address => ({
            label: address.name,
            value: address.addressId,
          }))}
          onChange={e => setSelectedShippingAddressId(e.target.value as string)}
          info="Weitere Adressen können über Ihren Westfalen-Shop-Account hinzugefügt werden."
        />

        <div className={styles['gaas-changeAddressModal']}>
          <span>{addressIdContent?.name}</span>
          <span>Werk XY</span>
          <span>{addressIdContent?.street}</span>
          <span>
            {addressIdContent?.zipCode} {addressIdContent?.city}
          </span>
          <span>Land</span>
        </div>
      </>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Lieferadresse auswählen"
      maxWidth={480}
      onSave={{
        disabled: false,
        loading: false,
        action: () => {
          setShippingAddressId(selectedShippingAddressId);
          onClose();
        },
      }}
    >
      {address}
    </Modal>
  );
};

export default connect(mapStateToProps, dispatchProps)(ChangeAddressModal);
