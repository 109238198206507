import { FC, useCallback, useEffect, useState } from 'react';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { Input } from '../../../stories/Input';
import { Select } from '../../../stories/Select';
import styles from './ShopItemSelect.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { Package } from '../../../stories/icons';
import classNames from 'classnames';
import ChangeAddressModal from './ChangeAddressModal';
import { Skeleton } from '../../../stories/Skeleton';
import {
  ApiCalculateCartRequest,
  ApiCartItem,
  ApiGetShopAddressesResponse,
} from '../../../models/openapi/openapiTypes';
import * as shopSelectors from '../selectors';

const mapStateToProps = (state: RootState) => ({
  cart: shopSelectors.currentCart(state.shop),
});

const dispatchProps = {};

interface ComponentProps {
  item?: ApiCartItem;
  name: string;
  options: { label: string; value: string }[];
  onItemQuantityAndAddressChanged: (
    itemId: string,
    quantity: number,
    shippingAddressId: string
  ) => void;
  onDelete?: () => void;
  addresses: ApiGetShopAddressesResponse;
  cart: ApiCalculateCartRequest;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopItemSelect: FC<Props> = ({
  item,
  name,
  options,
  onItemQuantityAndAddressChanged,
  onDelete,
  addresses,
  cart,
}) => {
  const [itemId, setItemId] = useState<string | undefined>(item?.itemId);
  const [numItems, setNumItems] = useState<number>(item ? item?.quantity : 1);
  const [shippingAddressId, setShippingAddressId] = useState<
    string | undefined
  >(item?.shippingAddressId);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setShippingAddressId(addresses.shippingAddresses[0].addressId);
  }, [addresses, setShippingAddressId]);

  const onItemsChanged = useCallback(
    (num: number, address: string, id?: string) => {
      setItemId(id);
      setNumItems(num);
      setShippingAddressId(address);

      if (!id) return;
      onItemQuantityAndAddressChanged(id, num, address);
      if (onDelete) {
        onDelete();
      }
    },
    [
      setNumItems,
      setShippingAddressId,
      onItemQuantityAndAddressChanged,
      onDelete,
    ]
  );

  useEffect(() => {
    const savedItem = cart.items.find(item => item.itemId === itemId);

    if (savedItem) {
      setNumItems(savedItem.quantity);
      setItemId(savedItem.itemId);
      setShippingAddressId(savedItem.shippingAddressId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let addressContent = <Skeleton height={45} />;

  if (shippingAddressId !== undefined) {
    const shippingAddressIdContent = addresses.shippingAddresses.find(
      address => address.addressId === shippingAddressId
    );
    addressContent = (
      <>
        <div className={styles['gaas-shopItemSelect--address--left--address']}>
          <span>{shippingAddressIdContent?.name}</span>
          <span>
            Werk XY, {shippingAddressIdContent?.street},{' '}
            {shippingAddressIdContent?.zipCode} {shippingAddressIdContent?.city}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles['gaas-shopItemSelect']}>
        <div className={styles['gaas-shopItemSelect--row']}>
          <div className={styles['gaas-shopItemSelect--input']}>
            <Input
              label="Menge"
              placeholder="Anzahl"
              type="number"
              value={numItems}
              name={`${itemId}|${shippingAddressId}-select`}
              onChange={event =>
                onItemsChanged(
                  Number(event.target.value),
                  shippingAddressId!,
                  itemId
                )
              }
              min={1}
              key={`${itemId}|${shippingAddressId}-select`}
            />
            <Select
              label="Gewindetyp"
              name={name}
              onChange={event =>
                onItemsChanged(
                  numItems,
                  shippingAddressId!,
                  event.currentTarget.value
                )
              }
              options={options}
              value={itemId}
            />
            <span className={styles['gaas-shopItemSelect--input--label']}>
              {/*{item && `Pro Nutzer ${item.price} EUR / Mon.`}*/}
            </span>
          </div>
          <div className={styles['gaas-shopItemSelect--input--button']}>
            <TextButton
              label="Löschen"
              onClick={
                !itemId
                  ? onDelete
                  : () => onItemsChanged(0, shippingAddressId!, itemId)
              }
            />
          </div>
        </div>
        <div
          className={classNames(
            styles['gaas-shopItemSelect--row'],
            styles['gaas-shopItemSelect--address']
          )}
        >
          <div className={styles['gaas-shopItemSelect--address--left']}>
            <Package />
            {addressContent}
          </div>
          <TextButton
            label="Lieferadresse ändern"
            onClick={() => setModalOpen(true)}
          />
        </div>
      </div>
      {modalOpen && (
        <ChangeAddressModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          shippingAddressId={shippingAddressId}
          setShippingAddressId={setShippingAddressId}
          addresses={addresses}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopItemSelect);
