import { asyncResourceSelector } from '../../store/async-resource';
import { ShopState } from './reducer';

export const contractPreview = (state: ShopState) =>
  asyncResourceSelector(state.currentContractPreview);

export const addresses = (sapCustomerNo: string, state: ShopState) =>
  asyncResourceSelector(state.addresses[sapCustomerNo]);

export const shopItems = (state: ShopState) =>
  asyncResourceSelector(state.items);

export const accessibleCustomers = (state: ShopState) =>
  asyncResourceSelector(state.accessibleCustomers);

export const currentCart = (state: ShopState) => state.currentCart;
export const currentCalculatedCart = (state: ShopState) =>
  state.currentCalculatedCart;
