import styles from './ShopWizard.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { Info } from '../../../stories/icons';
import ShopItemSelect from './ShopItemSelect';
import { Devider } from '../../../stories/Devider';
import { Button } from '../../../stories/Button';
import ShopConfiguratorDeviceInfo from './ShopConfiguratorDeviceInfo';
import React, { useState } from 'react';
import {
  ApiCartItem,
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
} from '../../../models/openapi/openapiTypes';
import * as shopSelectors from '../selectors';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  addressData: ApiGetShopAddressesResponse;
  onItemQuantityAndAddressChanged: (
    itemId: string,
    quantity: number,
    shippingAddressId: string
  ) => void;
}

const mapStateToProps = (state: RootState) => ({
  cart: shopSelectors.currentCart(state.shop),
});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopConfiguratorStepGateways: React.FC<Props> = ({
  shopItemEntries,
  cart,
  addressData,
  onItemQuantityAndAddressChanged,
}) => {
  const [infoVisible, setInfoVisible] = useState<boolean>(false);
  const [addNew, setAddNew] = useState(false);

  const availableGateways = shopItemEntries.filter(
    it => it.category === 'meter'
  );

  const selectedGateways: ApiCartItem[] = cart.items
    .filter(item => item.quantity > 0)
    .filter(
      item =>
        shopItemEntries.find(it => it.itemId === item.itemId)?.category ===
        'gateway'
    );

  return (
    <>
      <div className={styles['gaas-shopPage--content--selection--info']}>
        <span>
          Bitte wählen Sie aus, wie viele LTE- oder WLAN-kompatible Geräte Sie
          benötigen.
        </span>
        <TextButton
          LeadingIcon={Info}
          onClick={() => setInfoVisible(!infoVisible)}
          inline
        />
      </div>
      {infoVisible && <ShopConfiguratorDeviceInfo />}

      {selectedGateways.length ? (
        selectedGateways.map((item, index) => (
          <>
            <ShopItemSelect
              item={item}
              name="gateways"
              options={availableGateways.map(option => ({
                label: option.name,
                value: option.itemId,
              }))}
              onItemQuantityAndAddressChanged={onItemQuantityAndAddressChanged}
              key={`${item.itemId}${index}`}
              addresses={addressData}
            />
            <Devider noMargin />
          </>
        ))
      ) : (
        <>
          <ShopItemSelect
            name="gateways"
            options={availableGateways.map(option => ({
              label: option.name,
              value: option.itemId,
            }))}
            onItemQuantityAndAddressChanged={onItemQuantityAndAddressChanged}
            addresses={addressData}
            key={'newgatewayitem'}
          />
          <Devider noMargin />
        </>
      )}
      {addNew && (
        <>
          <ShopItemSelect
            name="gateways"
            options={availableGateways.map(option => ({
              label: option.name,
              value: option.itemId,
            }))}
            onItemQuantityAndAddressChanged={onItemQuantityAndAddressChanged}
            onDelete={() => setAddNew(false)}
            addresses={addressData}
            key={'newgatewayitem'}
          />
          <Devider noMargin />
        </>
      )}

      <div>
        <Button
          label="Weitere hinzufügen"
          variant="outlined"
          onClick={() => setAddNew(true)}
        />
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ShopConfiguratorStepGateways);
