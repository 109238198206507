import React, { useEffect, useRef } from 'react';
import styles from './ShopItem.module.scss';
import { TextButton } from './TextButton';
import { Download } from './icons';
import useWindowSize from '../hooks/useWindowSize';

interface Props {
  currentIndex: number;
  imgUrl?: string;
  documentUrl?: string;
}

export const ShopItem: React.FC<Props> = ({
  currentIndex,
  imgUrl,
  documentUrl,
}) => {
  const imgContainerRef = useRef<HTMLDivElement>(null);
  let { width } = useWindowSize();

  useEffect(() => {
    if (imgContainerRef.current) {
      let width = imgContainerRef.current.clientWidth;
      let height = (width / 16) * 9;
      imgContainerRef.current.style.height = height.toString() + 'px';
    }
  }, [imgContainerRef, width, currentIndex]);

  let introduction = [
    'Wir empfehlen Ihnen, mindestens einen PREMIUM-Nutzer zu Ihrem Paket hinzuzufügen, um Ihnen die Verwaltung der Rechte Ihrer BASIC-Nutzer zu erleichtern. Bei Bedarf können Sie zu einem späteren Zeitpunkt bequem weitere User hinzubuchen.',
    'Mit Hilfe des GaaS® Meters werden Füllstand (Druck, Temperatur) der im Einsatz befindlichen Gasversorgungssysteme ermittelt und über das GaaS® Gate in das Online Portal GaaS® Hub übertragen. Hierzu wird einmalig das analoge Eingangsmanomenter ihres Druckminderers durch das GaaS® Meter ausgetauscht.',
    'Das GaaS® Gate ist ein IoT-Gateway. Es empfängt die Füllstandsdaten der GaaS® Meter und leitet diese an eine Cloud weiter. Über das cloudbasierte GaaS® Hub können die Daten abgerufen werden.',
  ];

  let description = [
    [
      ['Vorteile BASIC-Nutzer', 'Flaschen Scannung'],
      [
        'Vorteile PREMIUM-Nutzer',
        'Flaschen Scannung',
        'Individuelle Erstellung von Markern/Alarmen',
        'Download von Verbrauchsstatistiken',
      ],
    ],
    [
      [
        'GaaS Meter',
        'Energiesparendes e-ink Display',
        'Messbereich von 0 &ndash; 400 bar',
        'Thermometer zur Berechnung des Gasvolumens',
        'Wechsel- und/oder aufladbarer Lithium Akku',
        'OTA Updatefähig',
        'BLE und LoRa WAN',
        'USB-C Anschluss',
      ],
    ],
    [
      [
        'GaaS Gate LTE',
        'Leitet Daten von GaaS Metern in der Umgebung an die Cloud weiter',
        'Kommunikation per LTE* oder WLAN zur Cloud',
        'Empfängt BLE oder LoRa WAN*',
      ],
      [
        'GaaS Gate WLAN',
        'Stromversorgung per USB-C (230 V Adapter enthalten)',
        'OTA Updatefähig',
      ],
    ],
  ];

  if (currentIndex >= 0 && currentIndex <= 2) {
    return (
      <div className={styles['gaas-shop-item']}>
        <p>{introduction[currentIndex]}</p>
        <div className={styles['gaas-shop-item--details']}>
          <div
            className={styles['gaas-shop-item--details--img']}
            ref={imgContainerRef}
          >
            <img src={imgUrl} alt="" />
          </div>
          <div className={styles['gaas-shop-item--details--desc']}>
            <ul>
              {description[currentIndex].map(desc =>
                desc.map((str, index) =>
                  index === 0 ? (
                    <h4 key={index}>{str}</h4>
                  ) : (
                    <li key={index}>{str}</li>
                  )
                )
              )}
            </ul>
            <TextButton TrailingIcon={Download} label="Produktdatenblatt" />
          </div>
        </div>
      </div>
    );
  } else return <></>;
};
