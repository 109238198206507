import {
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';
import {
  ApiCalculateCartRequest,
  ApiCalculateCartResponse,
  ApiGetShopAccessibleCustomers,
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
  ApiRenderContractResponse,
} from '../../models/openapi/openapiTypes';
import { createReducer } from 'typesafe-actions';
import {
  calculateCartAsync,
  getShopAccessibleCustomersAsync,
  getShopAddressesAsync,
  getShopItemsAsync,
  renderContractAsync,
  storeCartLocal,
} from './actions';
import { SavedCart } from './models/SavedCart';

interface State {
  currentCart: ApiCalculateCartRequest;
  currentCalculatedCart?: AsyncResource<ApiCalculateCartResponse>;
  currentContractPreview?: AsyncResource<ApiRenderContractResponse>;
  addresses: Record<string, AsyncResource<ApiGetShopAddressesResponse>>;
  accessibleCustomers?: AsyncResource<ApiGetShopAccessibleCustomers>;
  items?: AsyncResource<ApiGetShopItemsResponse>;
}

const initialState: State = {
  // calculatedCarts: {},
  addresses: {},
  currentCart: {
    billingInterval: 'monthly',
    cartId: 'cartId',
    items: [],
    sapCustomerNo: '',
  },
};

export const shopReducer = createReducer(initialState)
  .handleAction(calculateCartAsync.request, state => ({
    ...state,
    currentCalculatedCart: asyncResourceRequest(),
  }))
  .handleAction(calculateCartAsync.success, (state, { payload }) => ({
    ...state,
    currentCalculatedCart: asyncResourceSuccess(payload),
  }))
  .handleAction(calculateCartAsync.failure, (state, { payload }) => ({
    ...state,
    currentCalculatedCart: asyncResourceFailure(payload.error),
  }))
  .handleAction(
    renderContractAsync.request,
    (state, { payload: { cartId } }) => ({
      ...state,
      currentContractPreview: asyncResourceRequest(),
    })
  )
  .handleAction(renderContractAsync.success, (state, { payload }) => ({
    ...state,
    currentContractPreview: asyncResourceSuccess(payload.contract),
  }))
  .handleAction(renderContractAsync.failure, (state, { payload }) => ({
    ...state,
    currentContractPreview: asyncResourceFailure(payload.error),
  }))
  .handleAction(
    getShopAddressesAsync.request,
    (state, { payload: { sapCustomerNo } }) => ({
      ...state,
      addresses: {
        ...state.addresses,
        [sapCustomerNo]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getShopAddressesAsync.success,
    (state, { payload: { addresses, sapCustomerNo } }) => ({
      ...state,
      addresses: {
        ...state.addresses,
        [sapCustomerNo]: asyncResourceSuccess(addresses),
      },
    })
  )
  .handleAction(
    getShopAddressesAsync.failure,
    (state, { payload: { sapCustomerNo, error } }) => ({
      ...state,
      addresses: {
        ...state.addresses,
        [sapCustomerNo]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(getShopItemsAsync.request, state => ({
    ...state,
    items: asyncResourceRequest(),
  }))
  .handleAction(getShopItemsAsync.success, (state, { payload }) => ({
    ...state,
    items: asyncResourceSuccess(payload),
  }))
  .handleAction(getShopItemsAsync.failure, (state, { payload }) => ({
    ...state,
    items: asyncResourceFailure(payload),
  }))
  .handleAction(getShopAccessibleCustomersAsync.request, state => ({
    ...state,
    accessibleCustomers: asyncResourceRequest(),
  }))
  .handleAction(
    getShopAccessibleCustomersAsync.success,
    (state, { payload }) => ({
      ...state,
      accessibleCustomers: asyncResourceSuccess(payload),
    })
  )
  .handleAction(
    getShopAccessibleCustomersAsync.failure,
    (state, { payload }) => ({
      ...state,
      accessibleCustomers: asyncResourceFailure(payload),
    })
  )
  // .handleAction(loadOrCreateCartForCustomer, (state, { payload:{sapCustomerNo, defaultShippingAddressId, defaultItems} }) => {
  //   const cartJson = localStorage.getItem(`GAAS-CART-${sapCustomerNo}`);
  //   if (cartJson) {
  //     const parsedCartCandidate = JSON.parse(cartJson) as SavedCart;
  //     if (parsedCartCandidate.version === 'v1') {
  //       return {
  //         ...state,
  //         currentCart: parsedCartCandidate.cart,
  //       };
  //     }
  //   }
  //
  //   return {
  //     ...state,
  //     currentCart: {
  //       billingInterval: 'monthly',
  //       cartId: uuidv4(),
  //       items: defaultItems,
  //       sapCustomerNo,
  //     },
  //   };
  //
  // })
  .handleAction(storeCartLocal, (state, { payload }) => {
    const cartToSave: SavedCart = {
      cart: payload,
      version: 'v1',
      lastModifiedAt: new Date().toISOString(),
    };
    localStorage.setItem(
      `GAAS-CART-${payload.sapCustomerNo}`,
      JSON.stringify(cartToSave)
    );

    return {
      ...state,
      currentCart: payload,
    };
  });

export default shopReducer;
export type ShopState = ReturnType<typeof shopReducer>;
