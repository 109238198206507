import React, { ReactElement, useEffect } from 'react';
import * as shopSelectors from '../selectors';
import * as shopActions from '../actions';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import ShopFullscreenLoading from './ShopFullscreenLoading';

interface ComponentProps {
  sapCustomerNo: string;
  children: ReactElement | null;
}

const mapStateToProps = (state: RootState) => ({
  currentCart: shopSelectors.currentCart(state.shop),
  shopItems: shopSelectors.shopItems(state.shop),
  addresses: (sapCustomerNo: string) =>
    shopSelectors.addresses(sapCustomerNo, state.shop),
});

const dispatchProps = {
  loadOrCreateCartForCustomer: shopActions.loadOrCreateCartForCustomer,
  requestShopItems: shopActions.getShopItemsAsync.request,
  getAddresses: shopActions.getShopAddressesAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopCartProvider: React.FC<Props> = ({
  currentCart,
  sapCustomerNo,
  addresses,
  getAddresses,
  requestShopItems,
  shopItems,
  children,
  loadOrCreateCartForCustomer,
}) => {
  useEffect(() => {
    if (!shopItems.loading && !shopItems.data && !shopItems.error) {
      requestShopItems();
    }
  }, [shopItems.data, shopItems.loading, shopItems.error, requestShopItems]);

  const addressesForCustomer = addresses(sapCustomerNo);
  useEffect(() => {
    if (
      !addressesForCustomer.loading &&
      !addressesForCustomer.data &&
      !addressesForCustomer.error
    ) {
      getAddresses({ sapCustomerNo });
    }
  }, [
    addressesForCustomer.data,
    addressesForCustomer.loading,
    addressesForCustomer.error,
    getAddresses,
    sapCustomerNo,
  ]);

  const { data: shopItemData } = shopItems;
  const { data: addressData } = addressesForCustomer;
  useEffect(() => {
    if (!shopItemData || !addressData) {
      return;
    }

    loadOrCreateCartForCustomer({
      sapCustomerNo,
      defaultItems: [
        {
          quantity: 1,
          shippingAddressId: addressData.billingAddress.addressId,
          itemId: 'sub-transparency',
        },
      ],
    });
  }, [loadOrCreateCartForCustomer, sapCustomerNo, addressData, shopItemData]);

  return currentCart.sapCustomerNo === sapCustomerNo ? (
    children
  ) : (
    <ShopFullscreenLoading />
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopCartProvider);
