import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ShopAuth } from '../../../util/shopAuth';
import ShopPage from './ShopPage';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PublicShopPage: React.FC<Props> = () => {
  const [authOk, setAuthOk] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const queryToken = query.get('jwt_token');

  useEffect(() => {
    if (queryToken) {
      ShopAuth.setToken(queryToken);
      history.push({
        ...location,
        search: undefined,
      });
      setAuthOk(true);
      return;
    }

    const storedToken = ShopAuth.getStoredToken();
    if (storedToken) {
      ShopAuth.setToken(storedToken);
      setAuthOk(true);
      return;
    }

    history.push('/');
  }, [history, location, queryToken, setAuthOk]);

  return authOk ? <ShopPage /> : <div></div>;
};

export default connect(mapStateToProps, dispatchProps)(PublicShopPage);
