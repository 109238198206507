import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Devider } from '../../../stories/Devider';
import styles from './ShopCheckout.module.scss';
import { Skeleton } from '../../../stories/Skeleton';
import { Input } from '../../../stories/Input';
import { ApiGetShopAddressesResponse } from '../../../models/openapi/openapiTypes';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

interface ComponentProps {
  selectedShippingAddress: string | undefined;
  setSelectedShippingAddress: (addressId: string) => void;
  addresses: ApiGetShopAddressesResponse;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopCheckout: FC<Props> = ({
  addresses,
  selectedShippingAddress,
  setSelectedShippingAddress,
}) => {
  useEffect(() => {
    setSelectedShippingAddress(addresses.shippingAddresses[0].addressId);
  }, [addresses, setSelectedShippingAddress]);

  let addressContent = (
    <>
      <h2>Rechnungsadresse</h2>
      <Skeleton height={24} width={150} mb={8} />
      <Skeleton height={24} width={150} mb={8} />
      <Skeleton height={24} width={120} />
      <Devider />
      <h2>Lieferung</h2>
      <Skeleton height={24} width={120} />
    </>
  );

  if (selectedShippingAddress !== undefined) {
    const { billingAddress } = addresses;
    addressContent = (
      <>
        <h2>Rechnungsadresse</h2>
        <div className={styles['gaas-shopCheckout--address']}>
          <span>{billingAddress.name}</span>
          <span>{billingAddress.street}</span>
          <span>
            {billingAddress.zipCode} {billingAddress.city}
          </span>
        </div>
        <Devider noMargin />
        <h2>Lieferung</h2>
        To do: Hier aufgeteilt Items nach Adressen
        {/* <div className={styles['gaas-shopCheckout--address']}>
          <span>{selectedShippingAddressContent?.name}</span>
          <span>{selectedShippingAddressContent?.street}</span>
          <span>
            {selectedShippingAddressContent?.zipCode}{' '}
            {selectedShippingAddressContent?.city}
          </span>
        </div> */}
        <Devider noMargin />
        <h2>Weitere Informationen</h2>
        <Input
          label="Vertragsreferenz"
          placeholder="Bestell- oder Vertragsnummer"
          type="text"
          max={35}
          onChange={() => null}
          name="contract"
        />
      </>
    );
  }

  return <div className={styles['gaas-shopCheckout']}>{addressContent}</div>;
};

export default connect(mapStateToProps, dispatchProps)(ShopCheckout);
