import { FC, useCallback, useEffect, useState } from 'react';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { Input } from '../../../stories/Input';
import { SavedCart } from '../models/SavedCart';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

interface ComponentProps {
  itemId: string;
  itemName: string;
  onItemQuantityChanged: (itemId: string, quantity: number) => void;
  lastCart?: SavedCart;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopItemInput: FC<Props> = ({
  itemName,
  itemId,
  onItemQuantityChanged,
  lastCart,
}) => {
  const [numItems, setNumItems] = useState<number>(0);

  const onNumItemsChanged = useCallback(
    (num: number) => {
      setNumItems(num);
      onItemQuantityChanged(itemId, num);
    },
    [setNumItems, onItemQuantityChanged, itemId]
  );

  useEffect(() => {
    const savedItem = (lastCart?.cart?.items ?? []).find(
      item => item.itemId === itemId
    );
    if (savedItem) {
      setNumItems(savedItem.quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onNumItemsChanged]);

  return (
    <Input
      label={itemName}
      placeholder="Anzahl"
      type="number"
      value={numItems}
      name={itemId}
      onChange={event => onNumItemsChanged(Number(event.target.value))}
      min={0}
      key={`${itemId}-input`}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopItemInput);
