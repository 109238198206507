import { ShopSummaryContent } from '../../../stories/ShopSummaryContent';
import {
  ApiBillingInterval,
  ApiGetShopItemsResponse,
} from '../../../models/openapi/openapiTypes';
import { Button } from '../../../stories/Button';
import * as shopSelectors from '../selectors';
import * as commonSelectors from '../../common/selectors';
import * as shopActions from '../actions';
import { RootState } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ShopDownloadContractButton from './ShopDownloadContractButton';

const mapStateToProps = (state: RootState) => ({
  cart: shopSelectors.currentCart(state.shop),
  calculatedCart: shopSelectors.currentCalculatedCart(state.shop),
  user: commonSelectors.user(state.common),
  contractPreview: shopSelectors.contractPreview(state.shop),
});

const dispatchProps = {
  submitCart: shopActions.submitCartAsync.request,
  changeBillingInterval: shopActions.currentCartChangeBillingInterval,
};

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  isAtCheckout: boolean;
  selectedShippingAddress: string | undefined;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopSummary: React.FC<Props> = ({
  selectedShippingAddress,
  user,
  calculatedCart,
  shopItemEntries,
  cart,
  isAtCheckout,
  changeBillingInterval,
}) => {
  const history = useHistory();
  const data = calculatedCart?.data;
  const loading = calculatedCart?.loading ?? true;

  return (
    <ShopSummaryContent
      isAtCheckout={isAtCheckout}
      loading={loading || !data}
      shopItemEntries={shopItemEntries}
      calcdItems={data?.items}
      total={data?.total}
      billingInterval={cart.billingInterval}
      setBillingInterval={event =>
        changeBillingInterval(
          event.target.value as unknown as ApiBillingInterval
        )
      }
      CheckoutButton={
        user ? (
          <>
            <div>
              <h3>Vertragsvorschau</h3>
              <ShopDownloadContractButton />
            </div>
            {/* todo: erlauben wenn shop token vorhanden ist */}
            <Button
              disabled={selectedShippingAddress === undefined}
              // todo: erlauben wenn shop token vorhanden ist
              label="Zahlungspflichtig bestellen"
              variant="success"
              fullWidth
              // onClick={() =>
              //   submitCart({
              //     shippingAddressId: selectedShippingAddress!,
              //     cart: buildCart(),
              //     calculatedCart: cart.data!,
              //   })
              // }
            />
          </>
        ) : (
          <Button
            label="Anmelden um Bestellung abzuschließen"
            fullWidth
            // redirect to the authorized shop page
            onClick={() => {
              history.push(`/shop`);
            }}
          />
        )
      }
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopSummary);
